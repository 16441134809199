<template>

    <div class="page">
        <!--    <div>-->
        <div class="category">

            <div class="home_category">
                <ul class="menu">
                    <li v-for="item in categoryList" :key="item.id">
                        <RouterLink
                                :to="{ name: 'testPaperCenter', params: { matchName: item.value, matchIndex: item.index, langName: 'ALL', langIndex: 0 }}">
                            <span class="item_name">{{ item.name }}</span>
                        </RouterLink>
                        <RouterLink v-for="(child, index) in item.children" :key="child.id"
                                    :to="{ name: 'testPaperCenter', params: { matchName: item.value, matchIndex: item.index, langName: child.value, langIndex: child.index }}">
                            <span class="item_sub">{{ child.name }}</span>
                            <span v-if="index !== item.children.length - 1" class="slash">/</span>
                        </RouterLink>
                        <!-- 弹层layer位置-->
                        <div class="layer">
                            <h3>{{item.full_name}}</h3>
                            <ul class="detail-items" v-for="children_detail in item.childrenDetails">
                                <li>
                                    <h4 class="detail-item-title"
                                        @click="toPaperList(item.value,item.index,children_detail.value,children_detail.index)">
                                        {{children_detail.title}}
                                        <span class="el-icon-caret-right"></span>
                                    </h4>
                                    <el-tooltip class="item" effect="light"
                                                v-for="level in children_detail.detail"
                                                :content="children_detail.title === level.name ? children_detail.title : children_detail.title +' '+ level.name"
                                                placement="top"
                                                >
                                        <span @click="toPaperList(item.value,item.index,children_detail.value,children_detail.index,level.name,level.index)">{{level.name}}</span>
                                    </el-tooltip>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="banner_container">
                <el-carousel height="350px" :interval="50000">
                    <el-carousel-item v-for="(item,index) in carouselArr" :key="index">
                        <img :src="item" style="height:100%; width:100%;" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="other_container">
                <div data-log-act="expose" data-log-id="tools"
                     class="other-menus ">
                    <div class="other-menus-title">特色应用</div>
                    <div class="other-menu-item" @click="toPaperList()">
                        <div class="item_left">
                            <img src="@/assets/icon/paper2.svg" style="width: 70%;height: 70%;margin: 2px">
                        </div>
                        <div class="item_right">
                            <h3>试卷中心</h3>
                            <p>历年考试真题、模拟题实时更新</p>
                        </div>
                    </div>
                    <div class="other-menu-item" @click="toQuestionBank()">
                        <div class="item_left">
                            <img src="@/assets/icon/ti.svg" style="width: 70%;height: 70%;margin: 2px">
                        </div>
                        <div class="item_right">
                            <h3>题库搜题</h3>
                            <p>刷题、搜题、专项训练</p>
                        </div>
                    </div>
                    <div class="other-menu-item" @click="toExaminationRecord()">
                        <div class="item_left">
                            <img src="@/assets/icon/jl.svg" style="width: 60%;height: 60%;margin: 5px">
                        </div>
                        <div class="item_right">
                            <h3>考试记录</h3>
                            <p>刷题、搜题、跟踪学习</p>
                        </div>
                    </div>
                    <div class="other-menu-item" @click="toWrongBook()">
                        <div class="item_left">
                            <img src="@/assets/icon/cuo.svg" style="width: 60%;height: 60%;margin: 5px">
                        </div>
                        <div class="item_right">
                            <h3>错题本</h3>
                            <p>针对性强化学习,加深理解和记忆</p>
                        </div>
                    </div>
                    <div class="other-menu-item" @click="toTeacherManage()">
                        <div class="item_left">
                            <img src="@/assets/icon/jiaoxue.svg" style="width: 60%;height: 60%;margin: 5px">
                        </div>
                        <div class="item_right">
                            <h3>教学中心</h3>
                            <p>教学管理与服务</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="recommend">
            <div class="rec" @click="toPaperList('ALL',0,'ALL',0)">
                <h3>推荐</h3>
                <img src="@/assets/icon/lang/tuijian.svg">
            </div>

            <ul>
                <div class="half-line"></div>
                <li @click="toPaperList('ALL',0,'Scratch',1)"><img src="@/assets/icon/lang/scratch.svg"><span>Scratch 图形化</span>
                </li>
                <div class="half-line"></div>
                <li @click="toPaperList('ALL',0,'C',2)"><img src="@/assets/icon/lang/c.svg"><span>C/C++</span></li>
                <div class="half-line"></div>
                <li @click="toPaperList('ALL',0,'Python',3)"><img
                        src="@/assets/icon/lang/python.svg"><span>Python</span></li>
                <div class="half-line"></div>
                <li @click="toPaperList('ALL',0,'ROBOT',4)"><img src="@/assets/icon/lang/robot.svg"
                                                                 style="margin-top: -2px"><span>机器人技术</span></li>
                <div class="half-line"></div>
                <li @click="toPaperList('SYSW',6,'ALL',0)"><img src="@/assets/icon/lang/kj.svg"><span>科学素养/计算思维</span>
                </li>
            </ul>
            <a class="mod" @click="toPaperList('ALL',0,'ALL',0)">查看更多<span class="el-icon-arrow-right"></span></a>
        </div>

        <newestNotic></newestNotic>

        <testPaperCenter ref="testPaperCenter" style="margin-top: 50px;"></testPaperCenter>


    </div>
</template>

<script>
    import loginDialog from "./components/loginDialog.vue";
    import newestNotic from "./components/newestNotic.vue";
    import testPaperCenter from "./components/testPaperCenter.vue";

    export default {
        components: {
            loginDialog,
            newestNotic,
            testPaperCenter
        },
        data() {
            return {
                carouselArr: [
                    require("@/assets/banner/1.png"),
                    require("@/assets/banner/2.png"),
                    require("@/assets/banner/3.png"),
                    require("@/assets/banner/4.png"),
                    // "http://www.wancode.net:8585/wancode/back/image/read/8928",
                    // "http://www.wancode.net:8585/wancode/back/image/read/8929",
                ],
                loginDialogShow: false,
                isHidden: false,
                dafaultContent: "",
                categoryList: [
                    {
                        "name": "电子学会",
                        "full_name": "中国电子学会青少年等级考试",
                        "value": "QCEIT",
                        "index": 1,
                        "children": [
                            {"name": "图形化编程", "value": "Scratch", "index": 1},
                            {"name": "C语言", "value": "C", "index": 2}
                        ],
                        "childrenDetails": [
                            {
                                "title": "图形化编程", "value": "Scratch", "index": 1,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "其他", "index": 5}
                                ]
                            },
                            {
                                "title": "C语言", "value": "C", "index": 2,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "五级", "index": 5},
                                    {"name": "六级", "index": 6},
                                    {"name": "七级", "index": 7},
                                    {"name": "八级", "index": 8},
                                    {"name": "九级", "index": 9},
                                    {"name": "十级", "index": 10},
                                    {"name": "其他", "index": 11}
                                ]
                            },
                            {
                                "title": "Python", "value": "Python", "index": 3,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "五级", "index": 5},
                                    {"name": "六级", "index": 6},
                                    {"name": "其他", "index": 7}
                                ]
                            },
                            {
                                "title": "机器人技术", "value": "ROBOT", "index": 4,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "五级", "index": 5},
                                    {"name": "六级", "index": 6},
                                    {"name": "七级", "index": 7},
                                    {"name": "八级", "index": 8}
                                ]
                            }
                        ]
                    }, {
                        "name": "CCF GESP",
                        "full_name": "GESP CCF编程能力等级认证",
                        "value": "CCF_GESP",
                        "index": 2,
                        "children": [{"name": "C/C++", "value": "C", "index": 2}, {
                            "name": "Python", "value": "Python", "index": 3
                        }],
                        "childrenDetails": [
                            {
                                "title": "Scratch", "value": "Scratch", "index": 1,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4}
                                ]
                            },
                            {
                                "title": "C++", "value": "C", "index": 2,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "五级", "index": 5},
                                    {"name": "六级", "index": 6},
                                    {"name": "七级", "index": 7},
                                    {"name": "八级", "index": 8}
                                ]
                            },
                            {
                                "title": "Python", "value": "Python", "index": 3,
                                "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "四级", "index": 4},
                                    {"name": "五级", "index": 5},
                                    {"name": "六级", "index": 6}
                                ]
                            }
                        ]
                    }, {
                        "name": "信息学奥赛",
                        "full_name": "全国青少年信息学奥林匹克竞赛",
                        "value": "NOI",
                        "index": 3,
                        "children": [
                            {"name": "NOI", "value": "NOI", "index": 2},
                            {"name": "CSP-J", "value": "CSP-J", "index": 4}],
                        "childrenDetails": [
                            {
                                "title": "NOI", "value": "NOI", "index": 2,
                                "detail": [
                                    {"name": "NOI", "index": 0},
                                    {"name": "其他", "index": 0}
                                ]
                            },
                            {
                                "title": "NOIP", "value": "NOIP", "index": 3,
                                "detail": [
                                    {"name": "普及组", "index": 1},
                                    {"name": "提高组", "index": 2}
                                ]
                            },
                            {"title": "CSP-J", "value": "CSP-J", "index": 4, "detail": [{"name": "CSP-J", "index": 0}]},
                            {"title": "CSP-S", "value": "CSP-S", "index": 5, "detail": [{"name": "CSP-S", "index": 0}]}
                        ]
                    },
                    {
                        "name": "蓝桥杯",
                        "full_name": "蓝桥杯全国软件和信息技术专业人才大赛",
                        "value": "LANQIAO",
                        "index": 4,
                        "children": [
                            {"name": "C/C++", "value": "LANQIAO_C", "index": 2},
                            {"name": "Python", "value": "LANQIAO_Python", "index": 3}
                        ],
                        "childrenDetails": [
                            {
                                "title": "Scratch", "value": "LANQIAO_Scratch", "index": 1,
                                "detail": [
                                    {"name": "初级", "index": 1},
                                    {"name": "中级", "index": 2},
                                    {"name": "其他", "index": 3}
                                ]
                            },
                            {
                                "title": "C++", "value": "LANQIAO_C", "index": 2, "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "其他", "index": 4}
                                ]
                            },
                            {
                                "title": "Python", "value": "LANQIAO_Python", "index": 3, "detail": [
                                    {"name": "初级", "index": 1},
                                    {"name": "中级", "index": 2},
                                    {"name": "高级", "index": 3},
                                    {"name": "其他", "index": 4}
                                ]
                            },
                            {
                                "title": "科技素养", "value": "SY", "index": 4, "detail": [
                                    {"name": "科技素养", "index": 0},
                                    {"name": "计算思维", "index": 0}
                                ]
                            },
                            {
                                "title": "Arduino", "value": "Arduino", "index": 5, "detail": [
                                    {"name": "一级", "index": 1},
                                    {"name": "二级", "index": 2},
                                    {"name": "三级", "index": 3},
                                    {"name": "其他", "index": 4}
                                ]
                            }
                        ]
                    },
                    {
                        "name": "NOC",
                        "value": "NOC",
                        "index": 5,
                        "full_name": "全国中小学信息技术创新与实践大赛",
                        "children": [
                            {"name": "Scratch", "value": "Scratch", "index": 1},
                            {"name": "Python", "value": "Python", "index": 3}
                        ],
                        "childrenDetails": [
                            {
                                "title": "Scratch",
                                "value": "Scratch",
                                "index": 1,
                                "detail": [{"name": "全部", "index": 0}]
                            },
                            {"title": "C++", "value": "C", "index": 2, "detail": [{"name": "全部", "index": 0}]},
                            {"title": "Python", "value": "Python", "index": 3, "detail": [{"name": "全部", "index": 0}]}
                        ]
                    },
                    {
                        "name": "科技素养",
                        "full_name": "全国青少年信息素养大赛",
                        "value": "SYSW",
                        "index": 6,
                        "children": [
                            {"name": "科技素养", "value": "SY", "index": 1},
                            {"name": "计算思维", "value": "SW", "index": 2}
                        ],
                        "childrenDetails": [
                            {"title": "科技素养", "value": "SY", "index": 1, "detail": [{"name": "全部", "index": 0}]},
                            {"title": "计算思维", "value": "SW", "index": 2, "detail": [{"name": "全部", "index": 0}]}
                        ]
                    },
                    {
                        "name": "其他",
                        "full_name": "其他",
                        "value": "OTHER",
                        "index": 7,
                        "children": [{"name": "算法竞赛", "value": "SF", "index": 1}],
                        "childrenDetails": [
                            {"title": "算法竞赛", "value": "SF", "index": 1, "detail": [{"name": "全部", "index": 0}]}
                        ]
                    }
                ]
            };
        },
        mounted() {
            // console.log(localStorage.getItem("token"))
            if (localStorage.getItem("token")) {
                this.loginDialogShow = false
            } else {
                this.loginDialogShow = true
            }
        }
        ,
        methods: {
            closeDialog() {
                this.isHidden = true;
                setTimeout(() => {
                    this.loginDialogShow = false
                })
            },
            loginSuccess() {
                this.$refs.testPaperCenter.getInfo()
            },
            toPaperList(matchName, matchIndex, langName, langIndex,levelName,levelIndex) {
                this.$router.push({
                    name: 'testPaperCenter',
                    params: {
                        matchName: matchName,
                        matchIndex: matchIndex,
                        langName: langName,
                        langIndex: langIndex,
                        levelName: levelName,
                        levelIndex:levelIndex
                    }
                });
                window.scrollTo(0, 0);
            },
            toQuestionBank() {
                this.$router.push({name: 'questionBank'});
                window.scrollTo(0, 0);
            },
            toMyWork() {
                this.$router.push({name: 'myWork'});
                window.scrollTo(0, 0);
            },
            toTeacherManage() {
                this.$router.push({name: 'teacherManage'})
                window.scrollTo(0, 0)
            },
            toExaminationRecord() {
                this.$router.push({name: 'examinationRecord'})
                window.scrollTo(0, 0)
            },
            toWrongBook() {
                this.$router.push({name: 'wrongBook'})
                window.scrollTo(0, 0)
            },
        }
    }
    ;
</script>

<style lang="scss" scoped>

    .category {
        width: 1200px;
        position: relative;
        margin: 20px auto;
        height: 350px;

        .home_category {
            position: relative;
            z-index: 99;
            text-align: left;

            width: 220px;
            height: 350px;

            .menu {
                position: relative;
                margin-right: 12px;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: #ffffff;
                font-size: 14px;
                z-index: 9;
                font-family: Microsoft YaHei;
                border-radius: 12px;
                box-shadow: 0 8px 30px rgba(0, 80, 179, .12);

                li {
                    overflow: hidden;
                    white-space: nowrap;
                    /*background: #ffffff;*/
                    transition: all 0.15s ease-in-out;
                    line-height: 45px;
                    padding: 1px 12px;


                    .item_name {
                        font-family: PingFang SC;
                        text-align: justify;
                        /*text-align-last: justify;*/
                        font-size: 14px;
                        color: #000;
                        font-weight: bold;
                        text-rendering: optimizeSpeed;
                        cursor: pointer;
                        display: inline-block;
                        margin-right: 30px;
                        width: 40px;
                        &:hover {
                            color: #ff3f29;
                            text-decoration: underline;
                        }

                    }

                    .item_sub {
                        color: #666;
                        display: inline-block;

                        &:hover {
                            color: #ff3f29;
                            text-decoration: underline;
                        }
                    }

                    .slash {
                        margin: 0 2px;
                    }


                    a {
                        margin-right: 4px;
                        text-decoration: none;
                    }

                    .layer {
                        width: 500px;
                        min-height: 350px;
                        /*height: 500px;*/
                        background: #fff;
                        position: absolute;
                        left: 220px;
                        top: 0;
                        z-index: 999;
                        display: none;
                        padding: 0 15px;
                        border-radius: 12px;
                        box-shadow: 0 8px 30px rgba(0, 80, 179, .12);
                        box-sizing: border-box;

                        h3 {
                            padding-top: 10px;
                            padding-left: 10px;
                            color: #000;
                        }

                        .detail-items {
                            padding-top: 10px;

                            li {
                                padding: 0px 10px;
                            }


                            .detail-item-title {
                                /*display: inline-block;*/
                                /*margin-bottom: 12px;*/
                                cursor: pointer;
                                user-select: none;
                                font-family: Microsoft YaHei;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                color: #333;
                                height: 20px;
                            }

                            li span {
                                font-size: 12px;
                                margin-right: 24px;
                                cursor: pointer;
                                color: #666;
                                line-height: 20px;

                                &:hover {
                                    color: #ff3f29;
                                    text-decoration: underline;
                                }
                            }
                        }

                    }

                    // 关键样式  hover状态下的layer盒子变成block
                    &:hover {
                        .layer {
                            display: block;
                        }
                    }
                }
            }
        }

        .banner_container {
            z-index: 98;
            position: absolute;
            left: 230px;
            top: 0;
            /*flex: none;*/
            width: 718px;
            height: 100%;
            background: #ffffff;
            border-radius: 12px;
            overflow: hidden;
            margin-right: 14px;
            /*display: block;*/
            /*unicode-bidi: isolate;*/
            box-shadow: 0 8px 30px rgba(0, 80, 179, .12);

        }

        .other_container {
            position: absolute;
            top: 0;
            right: 0;
            width: 240px;
            height: 350px;
            background: #fff;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 8px 30px rgba(0, 80, 179, .12);

            .other-menus {
                .other-menus-title {
                    color: #333;
                    padding: 12px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 600;
                    text-align: left;
                    background-color: #fff;
                    height: 14px;
                }


                .other-menu-item {
                    line-height: 62px;
                    /*background-color: red;*/
                    /*border-top: 1px solid #dadcdc;*/
                    width: 230px;
                    height: 62px;
                    /*display: block;*/
                    cursor: pointer;
                    padding-left: 12px;


                    &:hover {
                        overflow: hidden;
                        border-radius: 12px;
                        background-color: #F5F5F5;
                    }

                    .item_left {
                        margin: 0;
                        padding: 0;
                        float: left;
                        height: 56px;
                        line-height: 56px;
                        display: flex; /* 垂直居中 */
                        align-items: center; /* 垂直居中 */

                    }

                    .item_right {
                        float: left;

                        h3 {
                            margin: 0;
                            padding: 0;
                            padding-top: 5px;
                            height: 23px;
                            line-height: 28px;
                            font-size: 14px;
                            color: #000;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                            height: 28px;
                            line-height: 28px;
                            font-size: 12px;
                            color: #666;
                        }
                    }

                }

                .other-menu-more {

                    position: absolute;
                    height: 52px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    text-align: center;
                    padding-top: 15px;
                    box-sizing: border-box;
                    background: #fff;
                    cursor: pointer;

                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    color: #333333;
                    margin-right: 5px;
                }

            }
        }

    }

    .recommend {
        height: 60px;
        background-color: #fff;
        margin-top: 10px;
        border-radius: 12px;
        box-shadow: 0 4px 3px 3px rgba(0, 0, 0, 0.1);
        line-height: 60px;
        color: #00a4ff;

        .rec {
            float: left;
            margin-left: 30px;

            &:hover {
                color: #ff3f29;
                text-decoration: none;
                cursor: pointer;
            }

            h3 {
                float: left;
                height: 60px;
                line-height: 60px;
            }

            img {
                float: left;
                padding-left: 10px;
                padding-top: 9px;
            }
        }


        ul {
            float: left;
            margin-left: 30px;

            li {
                float: left;
                cursor: pointer;
                font-weight: 600;
                font-size: 16px;
                color: #333;
            }

            .half-line {
                float: left;
                height: 20px;
                border-left: 1px solid #ccc; /* 显示一条水平线条 */
                margin-top: 20px;
            }
        }


        .mod {
            float: right;
            margin-right: 10px;

            &:hover {
                color: #ff3f29;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }


    .recommend ul li {
        overflow: hidden;
        padding: 0 25px;
        font-size: 16px;
        color: #050505;

        img {
            float: left;
            padding-top: 13px;
            padding-right: 5px;
        }

        span {
            float: left;
        }

        &:hover {
            color: #ff3f29;
            text-decoration: underline;
        }
    }


</style>
