<template>

    <div class="paper">
        <div class="paper-hd">
            <h3>精选试卷</h3>
        </div>
        <div class="paper-bd">
            <ul v-if="allTestPaper.length === 0" class="clearfix">
                <li class="card" v-for="item in images">

                    <div class="image-container">
                        <img :src="item" lazy>
                        <div class="text-overlay">
                            <p>精选试卷</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>
                    <h4><i class="el-icon-loading"></i> 加载中......</h4>
                    <div class="card-labels">
                        <span>
                          精选试卷
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type">免费</span>
                        <span class="study-num">0人正在练习</span>
                    </div>
                </li>
            </ul>
            <ul v-else class="clearfix">
                <li class="card" v-for="(item,index) in allTestPaper.paperList1"
                    :key="item.id"
                    @click="jumpToDetails(item.id)"
                >

                    <div class="image-container">
                        <img :src="images[index]" lazy>
                        <div class="text-overlay">
                            <p>历年真题</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>
                    <h4>{{ item.name }}</h4>
                    <div class="card-labels">
                        <span v-for="label in item.paperLabels.split(',')" >
                            {{ label }}
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type" v-if="item.paperType== 0">免费</span>
                        <span class="paper-type" v-else>VIP</span>
                        <span class="study-num">{{studyNums[index]}}人正在练习</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="paper-hd">
            <h3>电子学会专题</h3>
        </div>
        <div class="paper-bd">
            <ul v-if="allTestPaper.length === 0" class="clearfix">
                <li class="card" v-for="item in 4">
                    <div class="image-container">
                        <img :src="images[imagesIndex[item-1]]" lazy>
                        <div class="text-overlay">
                            <p>电子学会</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>
                    <h4><i class="el-icon-loading"></i> 加载中......</h4>
                    <div class="card-labels">
                        <span>
                          电子学会试卷
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type">免费</span>
                        <span class="study-num">0人正在练习</span>
                    </div>
                </li>
            </ul>
            <ul v-else class="clearfix">
                <li class="card" v-for="(item,index) in allTestPaper.paperList2"
                    :key="item.id"
                    @click="jumpToDetails(item.id)">
                    <div class="image-container">
                        <img :src="images[imagesIndex[index]]" lazy>
                        <div class="text-overlay">
                            <p>电子学会</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>
                    <h4>{{ item.name }}</h4>
                    <div class="card-labels">
                        <span v-for="label in item.paperLabels.split(',')" >
                            {{ label }}
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type" v-if="item.paperType== 0">免费</span>
                        <span class="paper-type" v-else>VIP</span>
                        <span class="study-num">{{studyNums[index+4]}}人正在练习</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="paper-hd clearfix">
            <h3>信奥赛专题</h3>
        </div>
        <div class="paper-bd">
            <ul v-if="allTestPaper.length === 0" class="clearfix">
                <li class="card" v-for="item in 4">
                    <div class="image-container">
                        <img :src="images[4-item]" lazy>
                        <div class="text-overlay">
                            <p>信奥赛</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>
                    <h4><i class="el-icon-loading"></i> 加载中......</h4>
                    <div class="card-labels">
                        <span>
                          信奥赛专题试卷
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type">免费</span>
                        <span class="study-num">0人正在练习</span>
                    </div>
                </li>
            </ul>
            <ul v-else class="clearfix">
                <li class="card" v-for="(item,index) in allTestPaper.paperList3"
                    :key="item.id"
                    @click="jumpToDetails(item.id)">
                    <div class="image-container">
                        <img :src="images[4-index-1]" lazy>
                        <div class="text-overlay">
                            <p>信奥赛</p>
                            <p class="text-overlay-overflow">{{item.name}}</p>
                        </div>
                    </div>

                    <h4>{{ item.name }}</h4>
                    <div class="card-labels">
                        <span v-for="label in item.paperLabels.split(',')" >
                            {{ label }}
                        </span>
                    </div>
                    <div class="card-footer">
                        <span class="paper-type" v-if="item.paperType== 0">免费</span>
                        <span class="paper-type" v-else>VIP</span>
                        <span class="study-num">{{studyNums[index+8]}}人正在练习</span>
                    </div>
                </li>
            </ul>
        </div>

        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :before-close="handleClose">
            <h3 style="text-align: center;font-size:22px;">{{content}}</h3>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="text-align: center;font-size:20px;" @click="toSelfVIP()" round>升级会员，继续练习</el-button>
            	<div style="padding-bottom: 20px"></div>
                <span style="color: red" v-show="dialogVisible2">注：SVIP指的是年会员或者终身会员</span>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        homePaper,
        checkCountByAnswerPaperAndDay
    } from '@/api/index.js'

    export default {
        filters: {
            formatType(type) {
                switch (type) {
                    case 0:
                        return "Scratch"
                        break;
                    case 1:
                        return "C/C++"
                        break;
                    case 2:
                        return "Python"
                        break;
                    case 3:
                        return "NOIP"
                        break;
                    case 4:
                        return "机器人技术"
                        break;
                    case 5:
                        return "科技素养/计算思维"
                        break;
                    case 6:
                        return "无人机技术"
                        break;
                    default:
                        return "其他"
                        break;
                }
            }
        },
        data() {
            return {
                images: [
                    require("@/assets/paper/paper1.png"),
                    require('@/assets/paper/paper2.png'),
                    require('@/assets/paper/paper3.png'),
                    require('@/assets/paper/paper4.png')
                ],
                imagesIndex: [1, 3, 0, 2],
                studyNums: [5, 2, 10, 16, 8, 23, 16, 57, 6, 34, 7, 1],
                studyNum: 0,
                loading: false,
                dialogVisible: false,
                dialogVisible2: false,
                content: "此作品未开通权限，不可操作！",
                allTestPaper: []
            }
        },
        methods: {
            getStudyNumber() {
                // 生成一个0到100之间的随机数字
                for (let i = 0; i < 12; i++) {
                    this.studyNums[i] = this.studyNums[i] + Math.floor(Math.random() * 100);
                }
            },
            handleClose(done) {
                this.dialogVisible = false;
            },
            toSelfVIP() {
                this.dialogVisible = false;
                this.$router.push({path: "/selfVIP"});
            },
            getInfo() {
                this.loading = true;
                homePaper().then(res => {
                    if (res.code === "0000") {
                        this.allTestPaper = res.data;
                        this.loading = false;
                    }
                })
            },
            jumpToDetails(id) {
                checkCountByAnswerPaperAndDay(id).then(res => {
                    if (res.message === "此作品未开通权限，用户不可操作！") {
                        this.content = "此作品未开通权限不可操作！";
                        this.dialogVisible = true
                    } else if (res.message === "答题次数达到每日答题上限！可升级会员!") {
                        this.content = "您好，今日答题次数已达到上限！";
                        this.dialogVisible = true
                    } else if (res.message === "此作品未开通权限，用户不可操作") {
                        this.content = "此作品未开通权限不可操作！";
                        this.dialogVisible = true
                        this.dialogVisible2 = true
                    } else {
                        if (res.code === "0000") {
                            let routeData = this.$router.resolve({
                                path: "/testPaperDetails?id=" + id
                            });
                            window.open(routeData.href, '_blank');
                        } else {
                            this.$message({
                                message: res.message,
                                type: 'warning'
                            });
                        }
                    }
                })


            },
            // toPaperCenter() {
            //     this.$router.push({path: "testPaperCenter"})
            // }
        },
        mounted() {
            this.getInfo()
        },
        created() {
            this.getStudyNumber();
        },
    }
</script>

<style lang="scss" scoped>

    .image-container {
        position: relative;
        display: inline-block; /* 或者其他适合的布局方式 */
    }

    .image-container img {
        width: 100%; /* 根据需要调整宽度 */
        height: auto; /* 保持图片的宽高比 */
    }

    .text-overlay {
        position: absolute;
        top: 50px;
        left: 15px;
        width: 100%;
        height: 100%;
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        color: white; /* 文字颜色 */
        font-size: 20px; /* 文字大小 */
        pointer-events: none; /* 使文字层可点击，通过这种方式可以让底下的图片接收点击事件 */


        p {
            margin: 0;
            line-height: 1.5;
            font-size: 22px;
        }

        .text-overlay-overflow{
            width: 160px; /* 定义容器宽度 */
            white-space: nowrap; /* 确保文本在一行内显示 */
            overflow: hidden; /* 隐藏超出容器的文本 */
        }

    }

    /* 清除浮动 */
    .clearfix::before,
    .clearfix::after {
        content: "";
        display: table;
    }

    .clearfix::after {
        clear: both;
    }

    .clearfix {
        /* IE6、IE7 专有 */
        *zoom: 1;
    }

    .paper {


        .paper-hd {
            height: 45px;
            margin-top: 50px;


            h3 {
                float: left;
                font-size: 20px;
                color: #494949;
            }
        }

        .paper-bd {
            ul {
                width: 1225px;
            }

            /* 把li的父亲ul修改足够宽一行足够装下5个盒子就不会换行 */
            ul .card {
                float: left;
                width: 291px;
                height: 309px;
                background: #fff;
                border-radius: 6px;
                overflow: hidden;
                font-family: PingFangSC;
                cursor: pointer;
                margin: 0 12px 0 0;
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateY(-8px);
                    box-shadow: 0 3px 2px 0 rgba(95, 101, 105, 0.15);
                }
            }

            /* > 表示只让其子元素调整 */
            ul li > img {
                width: 100%;
                height: 164px;
            }

            ul li h4 {
                margin: 10px 10px 10px 10px;
                font-weight: 400;
                color: #333;

                height: 48px;
                line-height: 24px;
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            .card-labels {
                margin-left: 12px;
                margin-bottom: 12px;
                height: 22px;
                line-height: unset;
                overflow: hidden;

                span {
                    display: inline-block;
                    position: relative;
                    margin-right: 6px;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 22px;
                    line-height: 22px;
                    padding: 0 4px;
                    background: #edeff2;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #667280;
                }
            }

            .card-footer {
                margin: 0 10px;
                justify-content: space-between;
                height: 20px;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #9e9e9e;

                .paper-type {
                    margin-right: 6px;
                    font-size: 16px;
                    color: #ff7a38;
                    line-height: 19px;
                }

                .study-num {
                    font-size: 14px;
                    line-height: 20px;
                    color: #a1a9b2;
                }
            }

        }

    }
</style>
