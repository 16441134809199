<template>
    <div class="data-div">
        <ul class="data-show">
            <li>
                <img src="@/assets/icon/data/user.svg">
                <div class="data-content number-container">
                    <!--                    <p class="">1,780,000+</p>-->
                    <span v-for="(char, index) in animatedNumber1" :key="index" class="num digit">
                      {{char}}
                    </span>
                    <span class="num">+</span>
                    <div class="label">用户总数</div>
                </div>

            </li>
            <li>
                <img src="@/assets/icon/data/panduan.svg">
                <div class="data-content">
                    <span v-for="(char, index) in animatedNumber2" :key="index" class="num digit">
                      {{char}}
                    </span>
                    <span class="num">+</span>
                    <div class="label">题目数量</div>
                </div>
            </li>
            <li>
                <img src="@/assets/icon/data/kaoshi.svg">
                <div class="data-content">
                     <span v-for="(char, index) in animatedNumber3" :key="index" class="num digit">
                      {{char}}
                    </span>
                    <span class="num">+</span>
                    <div class="label">累计考试人次</div>
                </div>
            </li>
            <li>
                <img src="@/assets/icon/data/pin.svg">
                <div class="data-content">
                     <span v-for="(char, index) in animatedNumber4" :key="index" class="num digit">
                      {{char}}
                    </span>
                    <span class="num">+</span>
                    <div class="label">累计评判次数</div>
                </div>
            </li>
        </ul>

    </div>
</template>

<script>

    export default {
        data() {
            return {
                actualNumber1: 0,
                actualNumber2: 0,
                actualNumber3: 0,
                actualNumber4: 0,
                targetNumber1: 1780004, // 目标数字
                targetNumber2: 2600004, // 目标数字
                targetNumber3: 7000004, // 目标数字
                targetNumber4: 6200004, // 目标数字
                isAnimating: true // 控制动画样式类的添加
            };
        },
        computed: {
            animatedNumber1() {
                return this.actualNumber1.toLocaleString('en-US', {minimumIntegerDigits: 5});
            },
            animatedNumber2() {
                return this.actualNumber2.toLocaleString('en-US', {minimumIntegerDigits: 5});
            },
            animatedNumber3() {
                return this.actualNumber3.toLocaleString('en-US', {minimumIntegerDigits: 5});
            },
            animatedNumber4() {
                return this.actualNumber4.toLocaleString('en-US', {minimumIntegerDigits: 5});
            }
        },
        mounted() {
            this.isAnimating = true;
            this.animateNumber1();
            this.animateNumber2();
            this.animateNumber3();
            this.animateNumber4();
        },
        methods: {
            animateNumber1() {
                const step1 = (this.targetNumber1 - this.actualNumber1) / 10;
                requestAnimationFrame(() => {
                    if (this.actualNumber1 < this.targetNumber1) {
                        this.actualNumber1 += step1;
                        this.actualNumber1 = Math.round(this.actualNumber1);
                        if (Math.abs(this.targetNumber1 - this.actualNumber1) > 1) {
                            this.animateNumber1();
                        }
                    }
                });
            },
            animateNumber2() {
                const step2 = (this.targetNumber2 - this.actualNumber2) / 10;
                requestAnimationFrame(() => {
                    if (this.actualNumber2 < this.targetNumber2) {
                        this.actualNumber2 += step2;
                        this.actualNumber2 = Math.round(this.actualNumber2);
                        if (Math.abs(this.targetNumber2 - this.actualNumber2) > 1) {
                            this.animateNumber2();
                        }
                    }
                });
            },
            animateNumber3() {
                const step3 = (this.targetNumber3 - this.actualNumber3) / 10;
                requestAnimationFrame(() => {
                    if (this.actualNumber3 < this.targetNumber3) {
                        this.actualNumber3 += step3;
                        this.actualNumber3 = Math.round(this.actualNumber3);
                        if (Math.abs(this.targetNumber3 - this.actualNumber3) > 1) {
                            this.animateNumber3();
                        }
                    }
                });
            },
            animateNumber4() {
                const step4 = (this.targetNumber4 - this.actualNumber4) / 10;
                requestAnimationFrame(() => {
                    if (this.actualNumber4 < this.targetNumber4) {
                        this.actualNumber4 += step4;
                        this.actualNumber4 = Math.round(this.actualNumber4);
                        if (Math.abs(this.targetNumber4 - this.actualNumber4) > 1) {
                            this.animateNumber4();
                        }
                    }
                });
            }
        }
    };

</script>

<style lang="scss" scoped>
    .data-div {
        width: 1200px;
        height: 180px;
        margin: 0px auto -50px;
        position: relative;
        /*border-radius: 12px;*/
        /*background-color: #fff;*/

        &:hover {
            text-decoration: none;
            cursor: default;
        }


        .data-show {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding-top: 70px;

            img {
                width: 59px;
                height: 59px;
                float: left;
            }

            .data-content {
                float: left;
                margin-left: 20px;
                text-align: left;
            }

            .num {
                font-size: 32px;
                font-weight: 700;
                /*color: #3776ff;*/
                color: #333;
                line-height: 32px;
                margin-bottom: 5px;
                margin-top: 0;
                /* 添加 letter-spacing 属性来调整字间距 */
                letter-spacing: -4.5px;

            }
            .label{
                margin-top: 5px;
            }
        }
    }

    .number-container {
        perspective: 1000px;
    }

    .number {
        font-size: 32px;
        font-family: Arial, sans-serif;
        color: #333;
        position: relative;
        overflow: hidden;
        height: 50px;
    }

    .number .digit {
        opacity: 0;
        transform: translateY(20px) scale(0.8);
        transition: opacity 0.2s, transform 0.2s;

    }

    .number.animate-in .digit {
        opacity: 1;
        transform: translateY(0) scale(1);
        transition-delay: 0.2s;
        transition-duration: 0.6s;

    }
</style>
